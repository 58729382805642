import { LoginResponse } from "app/types/User";
import { Task } from "app/user/tasks/Task";
import { action, makeAutoObservable } from "mobx";

class AppStore {
  loginResponse: LoginResponse;
  selectedEntity: number = -1;
  showEntityDialog: boolean = false;
  userEntities: Entity[] = [];
  loading: boolean = false;
  tasksData: Task[] = [];
  socket: any;
  toastOpen: boolean = false;
  toastMessage: string = "";
  toastSeverity: "error" | "success" | "info" | "warning" = "success";

  constructor() {
    this.loginResponse = {} as any;

    makeAutoObservable(this, {
      setSelectedEntity: action,
      setShowEntityDialog: action,
      setLoginResponse: action,
      setUserEntities: action,
      setToastOpen: action,
      setToastMessage: action,
      setToastSeverity: action,
      showToast: action,
      clearToast: action,
    });
  }

  setToastOpen(open: boolean) {
    this.toastOpen = open;
  }

  setToastMessage(message: string) {
    this.toastMessage = message;
  }

  setToastSeverity(severity: "error" | "success" | "info" | "warning") {
    this.toastSeverity = severity;
  }

  showToast(
    message: string,
    severity: "error" | "success" | "info" | "warning"
  ) {
    this.setToastOpen(true);
    this.setToastMessage(message);
    this.setToastSeverity(severity);
  }

  clearToast() {
    this.setToastMessage("");
    this.setToastSeverity("info");
  }

  async loadSocket() {}

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setCurrentTasks(tasks: Task[]) {
    this.tasksData = tasks;
  }

  setSelectedEntity(entity: number) {
    this.selectedEntity = entity;
  }

  setShowEntityDialog(showEntityDialog: boolean) {
    this.showEntityDialog = showEntityDialog;
  }

  setLoginResponse(response: LoginResponse) {
    this.loginResponse = response;
  }

  setUserEntities(entities: Entity[]) {
    this.userEntities = entities;
  }
}

const appStore = new AppStore();

export default appStore;
