import React, { createContext, useContext, useEffect } from "react";
import appStore from "./mobxStore/AppStore";
import { api } from "api/API";

const EntityContext = createContext(null);

export const EntityProvider = ({ children }) => {
  useEffect(() => {
    const storedEntityId = localStorage.getItem("selectedEntity");

    if (storedEntityId) {
      appStore.setSelectedEntity(Number(storedEntityId));
      localStorage.removeItem("selectedEntity");
    } else {
      const userId = appStore.loginResponse.user[0].userId;

      if (appStore.selectedEntity === -1) {
        api
          .get(`get-user-entities/${userId}`)
          .then((res) => {
            if (res.success) {
              if (res.data.length > 0) {
                const selectedEntityId = res.data[0]?.entityId ?? -1;
                const entities = res.data;
                appStore.setSelectedEntity(selectedEntityId);
                appStore.setUserEntities(entities ?? []);
              }
            }
          })
          .catch((err) => {
            console.error("API Error:", err);
          });
      }
    }
  }, []);

  // Save to localStorage before the page reloads
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("selectedEntity", String(appStore.selectedEntity));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [appStore.selectedEntity]);

  const updateEntity = (newEntity) => {
    appStore.setSelectedEntity(newEntity);
  };

  return (
    <EntityContext.Provider
      value={{
        entity: appStore.selectedEntity,
        entities: appStore.userEntities,
        updateEntity,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};

// Custom hook to access the context
export const useEntity = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error("useEntity must be used within an EntityProvider");
  }
  return context;
};
